<template>
  <page-main
    title="Name and Contact Information"
    nextText="Address Information"
    buttonTabIndex="10"
    eventName="nav" 
    @nav="nav('/Contact')"
    @save="save()"
  >
    <template v-slot:aside>
      Let's begin by filling in some basic information, starting with your name
      and contact info.
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold">First Name:</span>
                John
              </li>
              <li>
                <span class="font-weight-bold">Middle Name:</span>
                B.
              </li>
              <li>
                <span class="font-weight-bold">Last Name:</span>
                Doe
              </li>
              <li>
                <span class="font-weight-bold">Suffix (Optional):</span>
              </li>
              <li>
                <span class="font-weight-bold">Prefix/Title (Optional):</span>
              </li>
              <li>
                <span class="font-weight-bold">Primary Phone Number:</span>
                (555) 555-5555
              </li>
              <li>
                <span class="font-weight-bold">Primary Email:</span>
                john.doethesuperfakeemail120830382@gmail.com
              </li>
            </ul>
          </li>
          <li>
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold">First Name:</span>
                John
              </li>
              <li>
                <span class="font-weight-bold">Middle Name:</span>
                B.
              </li>
              <li>
                <span class="font-weight-bold">Last Name:</span>
                Doe
              </li>
              <li>
                <span class="font-weight-bold">Suffix (Optional):</span>
                Jr.
              </li>
              <li>
                <span class="font-weight-bold">Prefix/Title (Optional):</span>
                Dr.
              </li>
              <li>
                <span class="font-weight-bold">Primary Phone Number:</span>
                +1 (555) 555-5555
              </li>
              <li>
                <span class="font-weight-bold">Primary Email:</span>
                john.doethesuperfakeemail120830382@gmail.com
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="firstName"
            label="First Name"
            tabindex="1"
            :rules="[rules.required]"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="middleName"
            label="Middle Name"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="lastName"
            label="Last Name"
            tabindex="3"
            :rules="[rules.required]"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="suffix"
            label="Suffix (Optional)"
            tabindex="4"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="title"
            label="Prefix/Title (Optional)"
            tabindex="5"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="phoneNumber"
            label="Primary Phone Number"
            tabindex="6"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="email"
            label="Primary Email"
            tabindex="7"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="linkedin"
            label="LinkedIn Profile (Optional)"
            tabindex="8"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="otherLink"
            label="Personal Page (Optional)"
            tabindex="9"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { required } from "../utility/validation.js";
import Modal from "@/components/Modal.vue";

// @ is an alias to /src
export default {
  name: "Information",
  data: () => ({
    rules: {
      required: (value) => required(value),
    },
  }),
  components: { Modal },
  methods: {
    save() {
      this.computeName();
      this.saveName();
      this.saveAddress();
    },
    ...mapActions("name", ["saveName"]),
    ...mapActions("address", ["saveAddress"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    computeName() {
      let displayName =
        "" +
        (this.title.length > 0 ? this.title + " " : "") +
        (this.firstName.length > 0 ? this.firstName + " " : "") +
        (this.middleName.length > 0 ? this.middleName + " " : "") +
        (this.lastName.length > 0 ? this.lastName : "") +
        (this.suffix.length > 0 ? ", " + this.suffix : "");

      this.displayValue = displayName;

      return [displayName];
    },
  },
  mounted() {},
  computed: {
    ...mapFields("name", [
      "title",
      "firstName",
      "middleName",
      "lastName",
      "suffix",
      "displayValue",
    ]),
    ...mapFields("address", ["phoneNumber", "email", "linkedin", "otherLink"]),
    nameDisplay() {
      return this.computeName();
    },
    tips() {
      return [
        "A Prefix/Title is typically reserved for individuals who have achieved a higher credential or official title (i.e., Dr.). If you don’t have a prefix/title or you don’t know what this means, leave it blank.",
        "Add the primary phone number where you can be easily contacted, whether it is your mobile, home or other type of number.",
        "For email, we recommend you put the email you used to sign up for Resumation, or else the email you use the most so you see when a potential employer contacts you. We recommend having a professional email format, such as your name, and do not recommend using any current work email!",
        "For your phone number, we recommend using the following format: (OOO) OOO-OOOO, where area code is in parenthesis. If you need to add a country code, then we recommend the following format +1 (OOO) OOO-OOOO.",
      ];
    },
  },
};
</script>
